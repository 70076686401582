<template>
  <b-row>
    <b-col sm="12">
      <b-card header-tag="header" footer-tag="footer">
        <b-row>
          <b-col sm="7">
            <h4 id="traffic" class="card-title mb-0">Páginas</h4>
            <div class="small text-muted">Administrar las páginas que se muestran en el sitio web</div>
          </b-col>          
        </b-row>
      </b-card>

      <b-row align-h="between">
        <b-col sm="3">

          <b-card class="crud-page-card-language">
            <b-form-group label="Idioma">                
              <b-form-select v-model="language.selected" 
                              :options="language.opt"
                              @input="configLanguage">
              </b-form-select>                    
            </b-form-group>          
          </b-card>        

          <b-list-group>
            <b-list-group-item href="#" class="flex-column align-items-start" @click="openSettings()">
              <div class="d-flex w-100 justify-content-between">
                <h5 class="mb-1">Configuraciones</h5>
                <small>
                  <b-icon icon="chevron-double-right" class="h5"></b-icon>
                </small>
              </div>

              <p class="mb-1">
                Configuración general del sitio web
              </p>
            </b-list-group-item>
          </b-list-group> 

          <b-list-group>
            <b-list-group-item href="#" class="flex-column align-items-start mb-2" @click="openBlocks()">
              <div class="d-flex w-100 justify-content-between">
                <h5 class="mb-1">Bloques</h5>
                <small>
                  <b-icon icon="chevron-double-right" class="h5"></b-icon>
                </small>
              </div>

              <p class="mb-1">
                Creación personalizada de bloques de contenido
              </p>
            </b-list-group-item>
          </b-list-group> 

          <b-list-group v-if="parameters.havePopups">
            <b-list-group-item href="#" class="flex-column align-items-start" @click="openPopups()">
              <div class="d-flex w-100 justify-content-between">
                <h5 class="mb-1">Popups</h5>
                <small>
                  <b-icon icon="chevron-double-right" class="h5"></b-icon>
                </small>
              </div>

              <p class="mb-1">
                Administrar popups para mostrar en sitio web
              </p>
            </b-list-group-item>
          </b-list-group>     

          <b-list-group v-if="parameters.haveDownloads">
            <b-list-group-item href="#" class="flex-column align-items-start" @click="openDownloads()">
              <div class="d-flex w-100 justify-content-between">
                <h5 class="mb-1">Descargas</h5>
                <small>
                  <b-icon icon="chevron-double-right" class="h5"></b-icon>
                </small>
              </div>

              <p class="mb-1">
                Administrar archivos de descargas para sitio web
              </p>
            </b-list-group-item>
          </b-list-group>   

          <!-- DESCONTINUADOS -->
          <b-list-group v-if="parameters.haveSliders">
            <b-list-group-item href="#" class="flex-column align-items-start" @click="openSliders()">
              <div class="d-flex w-100 justify-content-between">
                <h5 class="mb-1">Sliders</h5>
                <small>
                  <b-icon icon="chevron-double-right" class="h5"></b-icon>
                </small>
              </div>

              <p class="mb-1">
                Administrar sliders para mostrar sitio web
              </p>
            </b-list-group-item>
          </b-list-group>                        

          <b-list-group v-if="parameters.havePaymentMethods">
            <b-list-group-item href="#" class="flex-column align-items-start" @click="openPaymentMethods()">
              <div class="d-flex w-100 justify-content-between">
                <h5 class="mb-1">Metodos de Pago</h5>
                <small>
                  <b-icon icon="chevron-double-right" class="h5"></b-icon>
                </small>
              </div>

              <p class="mb-1">
                Administrar métodos de pagos para visualizar en sitio web
              </p>
            </b-list-group-item>
          </b-list-group> 
          <!-- FIN DESCONTINUADOS -->

          <b-list-group v-if="canDeliveries">
            <b-list-group-item href="#" class="flex-column align-items-start mt-2" @click="openStoreDeliveries()">
              <div class="d-flex w-100 justify-content-between">
                <h5 class="mb-1">Entregas</h5>
                <small>
                  <b-icon icon="chevron-double-right" class="h5"></b-icon>
                </small>
              </div>

              <p class="mb-1">
                Administrar todas las formas de entregas de un pedidos
              </p>
            </b-list-group-item>
          </b-list-group> 

          <b-list-group v-if="canDeliveries">
            <b-list-group-item href="#" class="flex-column align-items-start" @click="openStoreDiscounts()">
              <div class="d-flex w-100 justify-content-between">
                <h5 class="mb-1">Cupones de Descuentos</h5>
                <small>
                  <b-icon icon="chevron-double-right" class="h5"></b-icon>
                </small>
              </div>

              <p class="mb-1">
                Administrar todos los cupones de descuentos disponibles para usar en la tienda
              </p>
            </b-list-group-item>
          </b-list-group>                   
        </b-col>
        <b-col sm="9">
          <b-tabs>
            <b-tab title="EN MENU" :active="tabActive=='en_menu'" @click="setTabActive('en_menu')">
              <b-card>            
                <b-row>
                  <b-col sm="7">
                    <h4 class="card-title">
                      Paginas que se acceden desde el menu
                    </h4>
                  </b-col>
                  <b-col sm="5">
                    <b-button @click="add()" type="button" variant="primary" size="sm" class="btn-pill float-right ml-2" v-b-tooltip.hover title="Agregar Páginas">
                      <i class="fa fa-plus"></i> Agregar
                    </b-button>                              
                  </b-col>
                </b-row>

                <b-row>
                  <b-col>
                    <b-table class="mb-0"
                            responsive
                            head-variant="dark"
                            :hover="true"
                            :small="true"
                            :fixed="true"
                            :sort-by.sync="tableSub.sortBy"
                            :sort-desc.sync="tableSub.sortDesc"                       
                            :items="table.items"
                            :fields="table.fields"
                            selectable
                            select-mode="single"
                            @row-selected="onRowSelected"                     
                            v-if="table.items.length"
                            style="min-height:100px">

                        <template v-slot:table-colgroup="scope">
                          <col
                            v-for="field in scope.fields"                    
                            :key="field.key"
                            :style="{ width: field.width }"
                          >
                        </template> 

                        <template v-slot:row-details="row">
                          <b-card>
                            <b-row v-if="!row.item.route_external && !row.item.route_hash">
                              <b-col md="12">
                                <b-row>
                                  <b-col md="6">
                                    <div class="contents-page-titulo-page mb-2">
                                      <b>Página:</b> {{row.item.name}}
                                    </div>
                                  </b-col>
                                  <b-col md="6">
                                    <b-button @click="addSub(row.item)" type="button" variant="secondary" size="sm" class="btn-pill float-right ml-2" v-b-tooltip.hover title="Agregar Bloque">
                                      <i class="fa fa-plus"></i> Agregar
                                    </b-button>
                                  </b-col>
                                </b-row>
                                <b-row>
                                  <b-col md="12">
                                    <b-table class="mb-0"
                                            responsive
                                            head-variant="dark"
                                            :hover="true"
                                            :small="true"
                                            :fixed="true"              
                                            :sort-by.sync="tableSub.sortBy"
                                            :sort-desc.sync="tableSub.sortDesc"                                    
                                            :items="tableSub.items"
                                            :fields="tableSub.fields"                            
                                            v-if="tableSub.items.length"
                                            style="min-height:200px">   

                                      <template v-slot:table-colgroup="scope">
                                        <col
                                          v-for="field in scope.fields"                    
                                          :key="field.key"
                                          :style="{ width: field.width }"
                                        >
                                      </template> 

                                      <template v-slot:cell(id)="row">
                                        <b>{{row.item.id}}</b>
                                      </template>

                                      <template v-slot:cell(preview)="row">                                                                   
                                        <div v-if="row.item.block">
                                          <b-img :src="row.item.block.image" width="150"/>
                                        </div>
                                      </template>  

                                      <template v-slot:cell(block_id)="row">                                                                   
                                        <div v-if="row.item.block">
                                          <b>{{row.item.block.name}}</b>
                                        </div>
                                      </template>  

                                      <template v-slot:cell(active)="row">                      
                                        <b-badge variant="success" v-if="row.item.active">Activo</b-badge>
                                        <b-badge variant="danger" v-else>Inactivo</b-badge>
                                      </template>   

                                      <template v-slot:cell(order)="row">                      
                                        <b-badge variant="dark" v-if="row.item.order">{{row.item.order}}</b-badge>
                                        <b-badge variant="secondary" v-else>Sin Orden</b-badge>
                                      </template>  
                                      
                                      <template v-slot:cell(observations)="row">            
                                        <div class="contents-page-tablesub-observations-configurate" v-if="row.item.content">
                                          <b>CONFIGURADO</b>
                                        </div>
                                        <div v-else class="contents-page-tablesub-observations-not-configurate">
                                          <b>SIN CONFIGURAR</b>
                                        </div>
                                      </template>                              
                                      
                                      <template v-slot:cell(f_action)="data">
                                        <b-dropdown right text="Acción" size="sm" variant="outline-dark" class="pull-right">
                                          <b-dropdown-item @click="goConfigurateBlock(data.item)">
                                            <b-icon icon="gear-fill"></b-icon> Configurar
                                          </b-dropdown-item>                                            
                                          <b-dropdown-item @click="openSourceCode(data.item)" v-if="data.item.block.type=='custom'">
                                            <b-icon icon="code-slash"></b-icon> Código Fuente
                                          </b-dropdown-item>                                            
                                          <b-dropdown-header>Acciones</b-dropdown-header>
                                          <b-dropdown-item @click="editSub(data.item)">
                                            <i class="fa fa-pencil ml-0 mr-0" style="color:blue"></i> Editar
                                          </b-dropdown-item>
                                          <b-dropdown-item @click="removeSub(data.item)">
                                            <i class="fa fa-trash mr-0 ml-0" style="color:red"></i> Eliminar
                                          </b-dropdown-item>
                                        </b-dropdown>
                                      </template>

                                    </b-table>
                                    <b-alert v-else variant="warning" show>No se encontraron registros</b-alert>
                                  </b-col>
                                </b-row>
                              </b-col>
                            </b-row>

                            <b-row v-if="row.item.route_external">
                              <b-col md="12">
                                <b-alert show variant="info">
                                  Esta página tiene un redireccionamiento externo a la URL {{row.item.route}}
                                </b-alert>
                              </b-col>
                            </b-row>

                            <b-row v-if="row.item.route_hash">
                              <b-col md="12">
                                <b-alert show variant="info">
                                  Esta página tiene un redireccionamiento a la sección #{{row.item.route}} de la página principal
                                </b-alert>
                              </b-col>
                            </b-row>

                          </b-card>
                        </template>                

                        <template v-slot:cell(id)="data">
                          <b>{{ data.item.id }}</b>
                        </template>

                        <template v-slot:cell(name)="data">
                          <div>
                            <b class="text-primary" v-if="!data.item.route">
                              <b-icon icon="house-fill"></b-icon>
                              Pricipal
                            </b>                                        
                          </div>
                          <div>
                            <b>Nombre:</b> {{ data.item.name }}
                          </div>

                          <div v-if="data.item.route_external"> 
                            <b>Ruta Externa:</b> <b class="text-secondary">{{ data.item.route }}</b>
                          </div>
                          <div v-if="data.item.route_hash"> 
                            <b>Ruta Sección:</b> <b class="text-secondary">#{{ data.item.route }}</b>
                          </div>                      
                          <div v-if="!data.item.route_external && !data.item.route_hash">
                            <b>Ruta Interna:</b> <b class="text-secondary">/{{ data.item.route }}</b>
                          </div>
                        </template>

                        <template v-slot:cell(hide_header)="data">                      
                          <b-icon icon="eye-slash-fill" v-if="data.item.hide_header" title="Ocultar Cabecera" variant="danger"></b-icon>                      
                          <b-icon icon="eye" v-else title="Mostrar Cabecera" variant="success"></b-icon>                      
                        </template>       

                        <template v-slot:cell(active)="data">                      
                          <b-badge variant="success" v-if="data.item.active">Activo</b-badge>
                          <b-badge variant="danger" v-else>Inactivo</b-badge>
                        </template>   

                        <template v-slot:cell(order)="data">                      
                          <b-badge variant="dark" v-if="data.item.order">{{data.item.order}}</b-badge>
                          <b-badge variant="secondary" v-else>Sin Orden</b-badge>
                        </template>   
                                      
                        <template v-slot:cell(observations)="data">                  
                          <div class="contents-page-table-observations" v-if="data.item.pages_blocks.length">
                            <b>Tiene ({{data.item.pages_blocks.length}}) bloques</b>
                          </div>
                          <div v-else class="text-warning">
                            <b>No se agrego ningun bloque</b>
                          </div>
                        </template>
                        
                        <template v-slot:cell(f_action)="data">
                          <b-dropdown right text="Acción" size="sm" variant="outline-dark" class="pull-right">
                            <b-dropdown-item @click="duplicate(data.item)">
                              <i class="fa fa-copy ml-0 mr-0" style="color:#000"></i> Duplicar
                            </b-dropdown-item>  
                            <b-dropdown-header>Acciones</b-dropdown-header>
                            <b-dropdown-item @click="edit(data.item)">
                              <i class="fa fa-pencil ml-0 mr-0" style="color:blue"></i> Editar
                            </b-dropdown-item>
                            <b-dropdown-item @click="remove(data.item)">
                              <i class="fa fa-trash mr-0 ml-0" style="color:red"></i> Eliminar
                            </b-dropdown-item>
                          </b-dropdown>
                        </template>
                    </b-table>
                    <b-alert v-else variant="warning" show>No se encontraron registros</b-alert>
                  </b-col>
                </b-row>
              </b-card>
            </b-tab>

            <b-tab title="SIN MENU" :active="tabActive=='sin_menu'" @click="setTabActive('sin_menu')">
              <b-card>          
                <b-row>
                  <b-col sm="7">
                    <h4 class="card-title">
                      Paginas que se acceden desde la URL
                    </h4>
                  </b-col>
                  <b-col sm="5">
                    <b-button @click="add()" type="button" variant="primary" size="sm" class="btn-pill float-right ml-2" v-b-tooltip.hover title="Agregar Páginas">
                      <i class="fa fa-plus"></i> Agregar
                    </b-button>                              
                  </b-col>
                </b-row>
                            
                <b-row>
                  <b-col>
                    <b-table class="mb-0"
                            responsive
                            head-variant="dark"
                            :hover="true"
                            :small="true"
                            :fixed="true"
                            :sort-by.sync="tableSub.sortBy"
                            :sort-desc.sync="tableSub.sortDesc"                       
                            :items="tableNoMenu.items"
                            :fields="tableNoMenu.fields"
                            selectable
                            select-mode="single"
                            @row-selected="onRowSelectedNoMenu"                     
                            v-if="tableNoMenu.items.length"
                            style="min-height:100px">

                        <template v-slot:table-colgroup="scope">
                          <col
                            v-for="field in scope.fields"                    
                            :key="field.key"
                            :style="{ width: field.width }"
                          >
                        </template> 

                        <template v-slot:row-details="row">
                          <b-card>
                            <b-row v-if="!row.item.route_external">
                              <b-col md="12">
                                <b-row>
                                  <b-col md="6">
                                    <div class="contents-page-titulo-page mb-2">
                                      <b>Página:</b> {{row.item.name}}
                                    </div>
                                  </b-col>
                                  <b-col md="6">
                                    <b-button @click="addSub(row.item)" type="button" variant="secondary" size="sm" class="btn-pill float-right ml-2" v-b-tooltip.hover title="Agregar Bloque">
                                      <i class="fa fa-plus"></i> Agregar
                                    </b-button>
                                  </b-col>
                                </b-row>
                                <b-row>
                                  <b-col md="12">
                                    <b-table class="mb-0"
                                            responsive
                                            head-variant="dark"
                                            :hover="true"
                                            :small="true"
                                            :fixed="true"              
                                            :sort-by.sync="tableSub.sortBy"
                                            :sort-desc.sync="tableSub.sortDesc"                                    
                                            :items="tableSub.items"
                                            :fields="tableSub.fields"                            
                                            v-if="tableSub.items.length"
                                            style="min-height:200px">   

                                      <template v-slot:table-colgroup="scope">
                                        <col
                                          v-for="field in scope.fields"                    
                                          :key="field.key"
                                          :style="{ width: field.width }"
                                        >
                                      </template> 

                                      <template v-slot:cell(id)="row">
                                        <b>{{row.item.id}}</b>
                                      </template>

                                      <template v-slot:cell(preview)="row">                                                                   
                                        <div v-if="row.item.block">
                                          <b-img :src="row.item.block.image" width="150"/>
                                        </div>
                                      </template>  

                                      <template v-slot:cell(block_id)="row">                                                                   
                                        <div v-if="row.item.block">
                                          <b>{{row.item.block.name}}</b>
                                        </div>
                                      </template>  

                                      <template v-slot:cell(active)="row">                      
                                        <b-badge variant="success" v-if="row.item.active">Activo</b-badge>
                                        <b-badge variant="danger" v-else>Inactivo</b-badge>
                                      </template>   

                                      <template v-slot:cell(order)="row">                      
                                        <b-badge variant="dark" v-if="row.item.order">{{row.item.order}}</b-badge>
                                        <b-badge variant="secondary" v-else>Sin Orden</b-badge>
                                      </template>  
                                      
                                      <template v-slot:cell(observations)="row">            
                                        <div class="contents-page-tablesub-observations-configurate" v-if="row.item.content">
                                          <b>CONFIGURADO</b>
                                        </div>
                                        <div v-else class="contents-page-tablesub-observations-not-configurate">
                                          <b>SIN CONFIGURAR</b>
                                        </div>
                                      </template>                              
                                      
                                      <template v-slot:cell(f_action)="data">
                                        <b-dropdown right text="Acción" size="sm" variant="outline-dark" class="pull-right">
                                          <b-dropdown-item @click="goConfigurateBlock(data.item)">
                                            <b-icon icon="gear-fill"></b-icon> Configurar
                                          </b-dropdown-item>  
                                          <b-dropdown-item @click="openSourceCode(data.item)" v-if="data.item.block.type=='custom'">
                                            <b-icon icon="code-slash"></b-icon> Código Fuente
                                          </b-dropdown-item>                                          
                                          <b-dropdown-header>Acciones</b-dropdown-header>
                                          <b-dropdown-item @click="editSub(data.item)">
                                            <i class="fa fa-pencil ml-0 mr-0" style="color:blue"></i> Editar
                                          </b-dropdown-item>
                                          <b-dropdown-item @click="removeSub(data.item)">
                                            <i class="fa fa-trash mr-0 ml-0" style="color:red"></i> Eliminar
                                          </b-dropdown-item>
                                        </b-dropdown>
                                      </template>

                                    </b-table>
                                    <b-alert v-else variant="warning" show>No se encontraron registros</b-alert>
                                  </b-col>
                                </b-row>
                              </b-col>
                            </b-row>
                            <b-row v-else>
                              <b-col md="12">
                                <b-alert show variant="info">
                                  Esta página tiene un redireccionamiento externo a la URL {{row.item.route}}
                                </b-alert>
                              </b-col>
                            </b-row>
                          </b-card>
                        </template>                

                        <template v-slot:cell(id)="data">
                          <b>{{ data.item.id }}</b>
                        </template>

                        <template v-slot:cell(name)="data">
                          <div>
                            <b class="text-primary" v-if="!data.item.route">
                              <b-icon icon="house-fill"></b-icon>
                              Pricipal
                            </b>                                        
                          </div>
                          <div>
                            <b>Nombre:</b> {{ data.item.name }}
                          </div>

                          <div v-if="data.item.route_external"> 
                            <b>Ruta Externa:</b> <b class="text-secondary">{{ data.item.route }}</b>
                          </div>
                          <div v-else>
                            <b>Ruta:</b> <b class="text-secondary">/{{ data.item.route }}</b>
                          </div>
                        </template>

                        <template v-slot:cell(hide_header)="data">                      
                          <b-icon icon="eye-slash-fill" v-if="data.item.hide_header" title="Ocultar Cabecera" variant="danger"></b-icon>                      
                          <b-icon icon="eye" v-else title="Mostrar Cabecera" variant="success"></b-icon>                      
                        </template>                       

                        <template v-slot:cell(active)="data">                      
                          <b-badge variant="success" v-if="data.item.active">Activo</b-badge>
                          <b-badge variant="danger" v-else>Inactivo</b-badge>
                        </template>   

                        <template v-slot:cell(order)="data">                      
                          <b-badge variant="dark" v-if="data.item.order">{{data.item.order}}</b-badge>
                          <b-badge variant="secondary" v-else>Sin Orden</b-badge>
                        </template>   
                                      
                        <template v-slot:cell(observations)="data">                  
                          <div class="contents-page-table-observations" v-if="data.item.pages_blocks.length">
                            <b>Tiene ({{data.item.pages_blocks.length}}) bloques</b>
                          </div>
                          <div v-else class="text-warning">
                            <b>No se agrego ningun bloque</b>
                          </div>
                        </template>
                        
                        <template v-slot:cell(f_action)="data">
                          <b-dropdown right text="Acción" size="sm" variant="outline-dark" class="pull-right">
                            <b-dropdown-item @click="duplicate(data.item)">
                              <i class="fa fa-copy ml-0 mr-0" style="color:#000"></i> Duplicar
                            </b-dropdown-item>      
                            <b-dropdown-header>Acciones</b-dropdown-header>                   
                            <b-dropdown-item @click="edit(data.item)">
                              <i class="fa fa-pencil ml-0 mr-0" style="color:blue"></i> Editar
                            </b-dropdown-item>
                            <b-dropdown-item @click="remove(data.item)">
                              <i class="fa fa-trash mr-0 ml-0" style="color:red"></i> Eliminar
                            </b-dropdown-item>
                          </b-dropdown>
                        </template>
                    </b-table>
                    <b-alert v-else variant="warning" show>No se encontraron registros</b-alert>
                  </b-col>
                </b-row>
              </b-card>
            </b-tab>
          </b-tabs>
        </b-col>
      </b-row>                            
      
      <b-card header-tag="header" footer-tag="footer" class="mt-1">
        <b-row>
          <b-col>
            <b-button type="button" variant="outline-dark" size="sm" @click="$router.go(-1)">
              <i class="fa fa-angle-double-left"></i>
              Volver
            </b-button>                        
          </b-col>         
        </b-row>
      </b-card>

      <!-- ########################### -->
      <!-- #####     MODALES     ##### -->
      <!-- ########################### -->

      <!-- CRUD MAIN -->
      <b-modal v-model="modal.form.active"
              header-bg-variant="dark"
              header-text-variant="white">
        <div slot="modal-header">
          {{this.modal.form.title}}
        </div>

        <b-tabs>
          <b-tab title="General" active>

            <b-row>   
              <b-col md="12">
                <b-form-group label="Nombre">
                  <b-form-input type="text"
                                size="sm"
                                v-model="crud.form.name"                                    
                                placeholder="Ingresar un nombre">
                  </b-form-input>
                </b-form-group>
              </b-col>            

              <b-col md="12">
                <b-form-group label="Orden">
                  <b-form-input type="number"
                                size="sm"
                                v-model="crud.form.order"                                  
                                placeholder="Ingresar el orden">
                  </b-form-input>
                </b-form-group>
              </b-col>

              <b-col sm="12">
                <b-form-group label="Ocultar Cabecera">                
                  <b-form-checkbox v-model="crud.form.hide_header" size="sm" switch></b-form-checkbox>                 
                </b-form-group>
              </b-col>     

              <b-col sm="12">
                <hr>
              </b-col>                   

              <b-col sm="12">
                <b-form-group label="Activar">                
                  <b-form-checkbox v-model="crud.form.active" size="sm" switch></b-form-checkbox>                 
                </b-form-group>
              </b-col>      
            </b-row>

          </b-tab>
          <b-tab title="Ruta">
            <b-row>
              <b-col sm="12">
                <b-form-group label="SIN MENU">                
                  <b-form-checkbox  v-model="crud.form.no_showing_menu" 
                                    size="sm" 
                                    switch
                                    @input="configRouteForm"></b-form-checkbox>                 
                </b-form-group>
              </b-col>  

              <b-col sm="12">
                <b-form-group label="Tipo de Ruta">                
                  <b-form-select v-model="crud.form.type_route" 
                                 :options="arr.select.typeRoute"
                                 @input="configRouteForm">
                  </b-form-select>                    
                </b-form-group>
              </b-col>   

              <b-col md="12">
                <b-form-group label="Ruta" :description="alert.route">
                  <b-form-input type="text"
                                size="sm"
                                v-model="crud.form.route"                                    
                                placeholder="Ingresar una ruta">
                  </b-form-input>
                </b-form-group>
              </b-col>                
            </b-row>
          </b-tab>
        </b-tabs>      

        <div slot="modal-footer">
          <b-button variant="outline-secondary" class="mr-1" @click="modal.form.active=false">Cancelar</b-button>
          <b-button variant="dark" @click="save()">Guardar</b-button>          
        </div>
      </b-modal>

      <!-- CRUD SUB -->      
      <b-modal v-model="modal.formSub.active"
              header-bg-variant="dark"
              header-text-variant="white">
        <div slot="modal-header">
          {{this.modal.formSub.title}}
        </div>

        <b-row>
          
          <b-col md="12">
            <FindObject render="search"
                        type="contentsBlock" 
                        @select-object="loadContentsBlock($event)" 
                        :valueID="crud.formSub.blocks_id"/>
          </b-col>  

          <b-col md="12" v-if="this.crud.formSub.block" class="mb-2">
            <b-img :src="crud.formSub.block.image" thumbnail fluid class="w-100"/>
          </b-col>          

          <b-col md="12">
            <b-form-group label="Orden">
              <b-form-input type="number"
                            size="sm"
                            v-model="crud.formSub.order"                                  
                            placeholder="Ingresar el orden">
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col sm="12">
            <b-form-group label="Activar">                
              <b-form-checkbox v-model="crud.formSub.active" size="sm" switch></b-form-checkbox>                 
            </b-form-group>
          </b-col>  
                 
        </b-row>

        <div slot="modal-footer">
          <b-button variant="outline-secondary" class="mr-1" @click="modal.formSub.active=false">Cancelar</b-button>
          <b-button variant="dark" @click="saveSub()">Guardar</b-button>          
        </div>
      </b-modal>      

      <!-- CRUD SOURCE CODE -->      
      <b-modal v-model="modal.sourceCode.active"
              no-close-on-esc
              no-close-on-backdrop
              hide-header-close            
              header-bg-variant="dark"
              header-text-variant="white"
              size="xl">
        <div slot="modal-header">
          {{this.modal.sourceCode.title}}
        </div>

        <b-row>                    
          <b-col md="12">
            <b-tabs>
              <b-tab title="Código" active>            
                <b-tabs pills card>
                  <b-tab title="HTML" active>            
                    <prism-editor v-model="crud.sourceCode.html" language="html" class="content-pages-block-source-code"></prism-editor>                        
                  </b-tab>
                  <b-tab title="CSS">            
                    <prism-editor v-model="crud.sourceCode.css" language="css" class="content-pages-block-source-code"></prism-editor>            
                  </b-tab>
                  <b-tab title="Javascript">            
                    <prism-editor v-model="crud.sourceCode.js" language="javascript" class="content-pages-block-source-code"></prism-editor>            
                  </b-tab>                            
                </b-tabs>
              </b-tab>
              <b-tab title="Variables">
                <b-tabs pills card>                  
                  <b-tab title="Contenido" active>
                    <b-row>           
                      <b-col md="12">                                                  
                        <json-tree v-if="source.variablesAvailables" :data="source.variablesAvailables" :level="2" class="source-code-pre-json"></json-tree>                  
                        <b-alert v-else variant="warning" show>No hay datos para mostrar</b-alert>
                      </b-col>
                    </b-row>
                  </b-tab>
                  
                  <b-tab title="Configuraciones">
                    <b-row>           
                      <b-col md="12">                                                  
                        <json-tree v-if="source.variablesAvailablesSettings" :data="source.variablesAvailablesSettings" :level="2"  class="source-code-pre-json"></json-tree>                  
                        <b-alert v-else variant="warning" show>No hay datos para mostrar</b-alert>
                      </b-col>
                    </b-row>
                  </b-tab>

                  <b-tab title="Utilizados">                  
                    <json-tree v-if="source.variablesUsed" :data="source.variablesUsed" class="source-code-pre-json"></json-tree>                  
                    <b-alert v-else variant="warning" show>Aun no se utilizó ninguna variable</b-alert>           
                  </b-tab>
                  
                  <b-tab title="Ayuda">
                    <b>Variables</b><br>
                    <div class="mt-1">
                      <b-icon scale="0.5" icon="circle-fill"></b-icon> {{this.getHelp(0)}}<br>
                    </div>
                    <div class="mt-1 mb-3">
                      <b-icon scale="0.5" icon="circle-fill"></b-icon> {{this.getHelp(1)}}<br>
                    </div>

                    <b>Formatos</b><br>
                    <div class="mt-1">
                      <b-icon scale="0.5" icon="circle-fill"></b-icon> {{this.getHelp(2)}}<br>
                    </div>                    
                    <div class="mt-1">
                      <b-icon scale="0.5" icon="circle-fill"></b-icon> {{this.getHelp(3)}}<br>
                    </div>
                    <div class="mt-1 mb-3">
                      <b-icon scale="0.5" icon="circle-fill"></b-icon> {{this.getHelp(4)}}<br>
                    </div>            

                    <b>Estilos</b><br>        
                    <div class="mt-1 mb-3">
                      <b-icon scale="0.5" icon="circle-fill"></b-icon> {{this.getHelp(5)}}<br>
                    </div>                    

                    <b>Bloques con Detalles</b><br>
                    <div class="mt-1 mb-3">
                      <b-icon scale="0.5" icon="circle-fill"></b-icon> {{this.getHelp(6)}}<br>
                    </div>                      
                  </b-tab>                
                </b-tabs>
              </b-tab>
            </b-tabs>
          </b-col>  
        </b-row>

        <div slot="modal-footer">          
          <b-button variant="outline-secondary" class="mr-1" @click="cancelSourceCode()">Salir</b-button>              
          <b-button variant="dark" @click="saveSourceCode()">Guardar</b-button>          
        </div>
      </b-modal>                  
    </b-col>
  </b-row>
</template>

<script>
  import serviceAPI from './services'
  import Error from '@/handler/error'
  import Session from '@/handler/session'  
  import Profiles from '@/config/profiles'
  import Modules from '@/config/modules'
  import Helper from '@/handler/helper' 
  import FindObject from '@/components/inc/find/findObject'
  import Param from '@/config/parameters'
  import Storage from '@/handler/storageSession'
  import PrismEditor from 'vue-prism-editor'
  import "prismjs";
  import "prismjs/themes/prism.css";

  import Vue from 'vue'
  import JsonTree from 'vue-json-tree'
  Vue.component('json-tree', JsonTree)

  export default {   
    components: {
      FindObject,
      PrismEditor,
    },         
    data: () => {
      return {      
        access: {
          module_id: Modules.CONTENIDOS,
          profile_id: Profiles.PERSONAL,
          view_reference: 'crudPages',
          elements: {}
        }, 
        parameters: {
          havePopups: Helper.hasParametersAccess(Param.P12),
          haveSliders: Helper.hasParametersAccess(Param.P11),
          haveDownloads: Helper.hasParametersAccess(Param.P13),
          havePaymentMethods: Helper.hasParametersAccess(Param.P14),          
        },         
        table : {
          sortBy: 'order',
          sortDesc: false,          
          items: [],
          fields: [
            {key: 'id', label: 'ID', class:"align-middle", width:"50px"},
            {key: 'name', label: 'Nombre', class:"align-middle", width:"150px"},                        
            {key: 'order', label: 'Orden', sortable: true, class:"align-middle text-center", width:"100px"},             
            {key: 'hide_header', label: 'Cabecera', class:"align-middle text-center", width:"100px"},
            {key: 'active', label: 'Estado', class:"align-middle text-center", width:"100px"},
            {key: 'observations', label: 'Observaciones', class:"align-middle", width:"300px"},
            {key: 'f_action', label:'', class:"align-middle", width:"100px"},
          ],
          mostrarFiltros: false,
        },
        tableSub : {
          sortBy: 'order',
          sortDesc: false,
          items: [],
          fields: [
            {key: 'id', label: 'ID', class:"align-middle", width:"50px"},
            {key: 'block_id', label: 'Bloque', class:"align-middle", width:"150px"},            
            {key: 'preview', label: 'Vista Referencia', class:"align-middle text-center", width:"200px"},                        
            {key: 'order', label: 'Orden', sortable: true, class:"align-middle text-center", width:"100px"},               
            {key: 'active', label: 'Estado', class:"align-middle text-center", width:"100px"},         
            {key: 'observations', label: 'Observaciones', class:"align-middle", width:"175px"},
            {key: 'f_action', label:'', class:"align-middle", width:"100px"},
          ],
          mostrarFiltros: false,
        }, 
        tableNoMenu: {
          sortBy: 'order',
          sortDesc: false,          
          items: [],
          fields: [
            {key: 'id', label: 'ID', class:"align-middle", width:"50px"},
            {key: 'name', label: 'Nombre', class:"align-middle", width:"150px"},                        
            {key: 'order', label: 'Orden', sortable: true, class:"align-middle text-center", width:"100px"},    
            {key: 'hide_header', label: 'Cabecera', class:"align-middle text-center", width:"100px"},         
            {key: 'active', label: 'Estado', class:"align-middle text-center", width:"100px"},
            {key: 'observations', label: 'Observaciones', class:"align-middle", width:"300px"},
            {key: 'f_action', label:'', class:"align-middle", width:"100px"},
          ],
          mostrarFiltros: false,
        },         
        crud: {
          form: {
            id: 0,
            type: '',
            name: '',
            route: '',
            order: 0,
            active: false,
            type_route: 'internal',
            route_external: false,
            route_hash: false,
            no_showing_menu: false, 
            language: 'es',
            hide_header: false,
          },     
          formSub: {
            id: 0,
            pages: null,
            pages_id: 0,
            block: null,
            blocks_id: 0,
            order: 0,
            content: '',
            active: false,
          },      
          sourceCode: {
            id: 0,
            html: '',
            css: '',
            js: '',
          }         
        },
        modal: {
          form: {
            active: false,
            title: ''
          },
          formSub: {
            active: false,
            title: ''
          },     
          sourceCode: {
            active: false,
            title: ''
          },                    
        },  
        arr: {
          page: [],    
          pageNoMenu: [],
          select: {
            typeRoute: [
              { value: 'internal', text: 'Ruta Interna'},
              { value: 'external', text: 'Ruta Externa'},
              { value: 'hash', text: 'Ruta Sección (Página Principal)'},
            ]
          },          
        },
        alert: {
          routes: ''
        },         
        language: {
          selected: 'es',
          opt: [
            { value: 'es', text: 'Español'},
            { value: 'en', text: 'Inglés'},
            { value: 'pt', text: 'Portugés'},
          ]
        },    
        tabActive: 'en_menu',
        indexTableMenuActive: 0,
        indexTableNoMenuActive: 0,
        source: {
          variablesAvailables: null,
          variablesAvailablesSettings: null,
          variablesUsed: null,
        }        
      }
    },
    created () {     
      /* Configurar permisos de vistas y elementos */
      Helper.hasAccessView(this.access)
      /* Fin configuracion */
    },  
    mounted() {      
      this.restoreFilterStorage()
      
      this.load()
      this.setup()      
    },
    computed: {
      canDeliveries() {
        var status1 = false
        this.arr.page.forEach(element => {          
          element.pages_blocks.forEach(element1 => {
            if(element1.block.reference == 'products') {
              var content = JSON.parse(element1.content)
              if(content.control.typeTransaction=="B2C" ||  
                 content.control.typeTransaction=="both") {
                status1 = true
              } else {
                status1 = false
              }
            }
          });
        });

        var status2 = false
        this.arr.pageNoMenu.forEach(element => {          
          element.pages_blocks.forEach(element1 => {
            if(element1.block.reference == 'products') {
              var content = JSON.parse(element1.content)
              if(content.control.typeTransaction=="B2C" ||  
                 content.control.typeTransaction=="both") {
                status2 = true
              } else {
                status2 = false
              }
            }
          });
        });

        return status1 || status2
      },
    },
    methods: {
      setup () {        
        if(Session.getSession().settings) {    
          this.primaryColor = Session.getSession().settings.color_primary
                    
          this.createCSS()
        }
      },         
      createCSS() {
        var hoja = document.createElement('style')
        hoja.innerHTML = ""      
        hoja.innerHTML = hoja.innerHTML  + ".list-group-item-action:hover, .list-group-item-action:focus { background-color: " + this.primaryColor + "; }"

        document.body.appendChild(hoja);
      }, 
     
      onRowSelected(item) {      
        this.arr.page.forEach((element, index) => {                    
          if(item.length){
            if(element.id == item[0].id) {

              if(this.table.items[index]._showDetails) {
                this.table.items[index]._showDetails = false
              } else {
                this.openDetail(index)
                this.indexTableMenuActive = index
              }              

            }
          }
        })
      },
      onRowSelectedNoMenu(item) {  
        this.arr.pageNoMenu.forEach((element, index) => {
          if(item.length){
            if(element.id == item[0].id) {

              if(this.tableNoMenu.items[index]._showDetails) {
                this.tableNoMenu.items[index]._showDetails = false
              } else {
                this.openDetailNoMenu(index)
                this.indexTableNoMenuActive = index
              }                     

            }
          }
        })        
      },     
      openDetail(index) {                                
        this.table.rowSelected = index
        this.load()
      },       
      openDetailNoMenu(index) {                                
        this.tableNoMenu.rowSelected = index
        this.load()
      },         
      load() {
        let loader = this.$loading.show();
        var result = serviceAPI.filtrarPagina({
          language: this.language.selected
        })

        result.then((response) => {
          loader.hide()
          var data = response.data

          this.table.items = []
          this.arr.page = []        
          this.tableNoMenu.items = []                    
          this.arr.pageNoMenu = []

          data.forEach(element => {
            if(element.no_showing_menu) {
              this.tableNoMenu.items.push(element)
              this.arr.pageNoMenu.push(element)
            } else {
              this.table.items.push(element)
              this.arr.page.push(element)
            }
          });
          
          if(this.table.rowSelected!=null) {
            if(this.table.items[this.table.rowSelected]) {
              this.table.items[this.table.rowSelected]._showDetails = true       
                                    
              if(this.table.items[this.table.rowSelected].pages_blocks) {                
                this.tableSub.items = this.table.items[this.table.rowSelected].pages_blocks
              }
            }
          }   
          
          if(this.tableNoMenu.rowSelected!=null) {
            if(this.tableNoMenu.items[this.tableNoMenu.rowSelected]) {
              this.tableNoMenu.items[this.tableNoMenu.rowSelected]._showDetails = true                   
            
              if(this.tableNoMenu.items[this.tableNoMenu.rowSelected].pages_blocks) {                
                this.tableSub.items = this.tableNoMenu.items[this.tableNoMenu.rowSelected].pages_blocks
              }
            }
          }             
        })
        .catch(error => {          
          loader.hide()
          this.$awn.alert(Error.showError(error))
        });        
      },
      add() {
        this.crud.form.id = 0
        this.crud.form.name = ''        
        this.crud.form.route = ''        
        this.crud.form.order = 0        
        this.crud.form.active = false
        this.crud.form.route_external = false
        this.crud.form.route_hash = false        
        this.crud.form.no_showing_menu = false
        this.crud.form.language = this.language.selected
        this.crud.form.hide_header = false

        this.crud.form.type_route='internal'

        this.configRouteForm()       
        
        this.modal.form.title = "Nueva Página"
        this.modal.form.active = true
      },
      edit(item) {        
        this.crud.form.id = item.id
        this.crud.form.type = 'edit'
        this.crud.form.name = item.name
        this.crud.form.route = item.route
        this.crud.form.order = item.order
        this.crud.form.active= item.active
        this.crud.form.route_external = item.route_external
        this.crud.form.route_hash = item.route_hash        
        this.crud.form.no_showing_menu = item.no_showing_menu
        this.crud.form.language = item.language
        this.crud.form.hide_header = item.hide_header

        if(!this.crud.form.route_external && !this.crud.form.route_hash) {
          this.crud.form.type_route='internal'
        } 
        if(this.crud.form.route_external) {
          this.crud.form.type_route='external'
        }        
        if(this.crud.form.route_hash) {
          this.crud.form.type_route='hash'
        }        

        this.configRouteForm()        

        this.modal.form.title = "Editar Página"
        this.modal.form.active = true
      },
      duplicate(item) {        
        this.crud.form.id = item.id
        this.crud.form.type = 'duplicate'
        this.crud.form.name = item.name
        this.crud.form.route = item.route
        this.crud.form.order = item.order
        this.crud.form.active= item.active
        this.crud.form.route_external = item.route_external
        this.crud.form.route_hash = item.route_hash        
        this.crud.form.no_showing_menu = item.no_showing_menu
        this.crud.form.language = item.language
        this.crud.form.hide_header = item.hide_header

        if(!this.crud.form.route_external && !this.crud.form.route_hash) {
          this.crud.form.type_route='internal'
        } 
        if(this.crud.form.route_external) {
          this.crud.form.type_route='external'
        }        
        if(this.crud.form.route_hash) {
          this.crud.form.type_route='hash'
        }        

        this.configRouteForm()        

        this.modal.form.title = "Duplicar Página"
        this.modal.form.active = true
      },
      remove(item) {
        this.crud.form.id = item.id
        this.crud.form.name = item.name        

        this.$bvModal.msgBoxConfirm('¿Desea borrar la página (' + this.crud.form.id + ') - '+ this.crud.form.name + '?', {
          title: 'Borrar Página',
          size: 'lg',
          buttonSize: 'lg',
          okVariant: 'danger',
          okTitle: 'SI',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          headerBgVariant: 'danger',
          headerTextVariant: 'white',
          hideHeaderClose: false,
          centered: true
        })
        .then(value => {
          if (value) {
            let loader = this.$loading.show();
            var result = serviceAPI.eliminarPagina(this.crud.form.id);

            result.then((response) => {      
              this.modal.form.active = false        
              loader.hide()
              this.load()
              this.$awn.success("Registro eliminado");
            })
            .catch(error => {
              loader.hide()
              this.$awn.alert(Error.showError(error));
            })
          }
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error));
        })
      },
      save() {                
        if(!this.crud.form.type_route) {
          this.$awn.alert("No se cargo el tipo de ruta");
          return false
        }

        let loader = this.$loading.show();

        if (this.crud.form.id) {
          if(this.crud.form.type == 'edit'){
            var result = serviceAPI.editarPagina(this.crud.form);
          } else {
            var result = serviceAPI.duplicarPagina(this.crud.form);
          }
        } else {
          var result = serviceAPI.agregarPagina(this.crud.form);          
        }

        result.then((response) => {
          this.modal.form.active = false
          loader.hide()
          this.load()
          this.$awn.success("Datos guardados con éxito");
        })
        .catch(error => {
          loader.hide()
          this.$awn.alert(Error.showError(error));
        })
      },
      configRouteForm(){           
        if(this.crud.form.no_showing_menu) {

          this.arr.select.typeRoute.forEach(element => {
            if(element.value=='hash') {
              var i = this.arr.select.typeRoute.indexOf( element );
              i !== -1 && this.arr.select.typeRoute.splice( i, 1 );              
            }               
          });

          this.arr.select.typeRoute.forEach(element => {
            if(element.value=='external') {
              var i = this.arr.select.typeRoute.indexOf( element );
              i !== -1 && this.arr.select.typeRoute.splice( i, 1 );              
            }               
          });          

          this.crud.form.type_route='internal'
          this.crud.form.route_external = false
          this.crud.form.route_hash = false             
        } else {

          var statusExternal = false
          this.arr.select.typeRoute.forEach(element => {
            if(element.value=='external') {
              statusExternal = true
            }
          })
          
          if(!statusExternal) {
            this.arr.select.typeRoute.push({ value: 'external', text: 'Ruta externa'})
          }

          var statusHash = false
          this.arr.select.typeRoute.forEach(element => {
            if(element.value=='hash') {
              statusHash = true
            }
          })
          
          if(!statusHash) {
            this.arr.select.typeRoute.push({ value: 'hash', text: 'Ruta Sección (Página Principal)'})
          }

        }

        if(this.crud.form.type_route=='internal') {
          this.alert.route = "Ruta interna por defecto (sin la barra '/'). Ej: contacto"
          this.crud.form.route_external = false
          this.crud.form.route_hash = false          
        }
        if(this.crud.form.type_route=='external') {
          this.alert.route = "Ruta externa. Ej: https://google.com"
          this.crud.form.route_external = true
          this.crud.form.route_hash = false                    
        }       
        if(this.crud.form.type_route=='hash') {
          this.alert.route = "Atributo data-id del bloque a visualizar en la página principal. Ej: b-contact-218"
          this.crud.form.route_external = false
          this.crud.form.route_hash = true                                  
        }                   
      },
      
      addSub(item) {
        this.crud.formSub.id = 0
        this.crud.formSub.pages_id = item.id
        this.crud.formSub.blocks_id = 0
        this.crud.formSub.order = 0
        this.crud.formSub.content = ''
        this.crud.formSub.active = false

        this.modal.formSub.title = "Nuevo Bloque"
        this.modal.formSub.active = true
      },
      editSub(item) {
        this.crud.formSub.id = item.id
        this.crud.formSub.pages_id = item.pages_id        
        this.crud.formSub.pages = item.page
        this.crud.formSub.blocks_id = item.blocks_id
        this.crud.formSub.block = item.block
        this.crud.formSub.order = item.order
        this.crud.formSub.content = item.content
        this.crud.formSub.active = item.active
        
        this.modal.formSub.title = "Editar Bloque"
        this.modal.formSub.active = true
      },
      removeSub(item) {
        this.crud.formSub.id = item.id
        if(item.block) {
          this.crud.formSub.name = item.block.name
        } else {
          this.crud.formSub.name = ''
        }
          
        this.$bvModal.msgBoxConfirm('¿Desea borrar el bloque (' + this.crud.formSub.id + ') - '+ this.crud.formSub.name + '?', {
          title: 'Borrar Bloque',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'danger',
          okTitle: 'SI',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          headerBgVariant: 'danger',
          headerTextVariant: 'white',
          hideHeaderClose: false,
          centered: true
        })
        .then(value => {
          if (value) {
            let loader = this.$loading.show();
            var result = serviceAPI.eliminarPaginaBloque(this.crud.formSub.id);

            result.then((response) => {      
              this.modal.formSub.active = false        
              loader.hide()
              this.load()
              this.$awn.success("Registro eliminado");
            })
            .catch(error => {
              loader.hide()
              this.$awn.alert(Error.showError(error));
            })
          }
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error));
        })
      },
      saveSub() {
        let loader = this.$loading.show();
        if (this.crud.formSub.id) {
          var result = serviceAPI.editarPaginaBloque(this.crud.formSub);
        } else {
          var result = serviceAPI.agregarPaginaBloque(this.crud.formSub);
        }

        result.then((response) => {
          this.modal.formSub.active = false
          loader.hide()
          this.load()
          this.$awn.success("Datos guardados con éxito");
        })
        .catch(error => {
          loader.hide()
          this.$awn.alert(Error.showError(error));
        })
      },
      
      loadContentsBlock (object) {
        if(object){
          this.crud.formSub.block = object
          this.crud.formSub.blocks_id = object.id
        } else {
          this.crud.formSub.block = null
          this.crud.formSub.blocks_id = 0
        }
      },      
      goConfigurateBlock(item) {
        this.saveFilterStorage()

        this.$router.push({ name: 'ContentsSttaffConfiguratePagesBlocks', params: {blockID: item.id} })
      },
      openPopups() {
        this.$router.push({ name: 'ContentsSttaffPopups' })
      },
      openSettings() {
        this.$router.push({ name: 'ContentsSttaffSettings' })
      },
      openBlocks() {
        this.$router.push({ name: 'ContentsSttaffBlocks' })
      },
      openSliders() {
        this.$router.push({ name: 'ContentsSttaffSliders' })
      },
      openDownloads() {
        this.$router.push({ name: 'ContentsSttaffDownloads' })
      },
      openPaymentMethods() {
        this.$router.push({ name: 'ContentsSttaffPaymentMethods' })
      },
      openStoreDeliveries() {
        this.$router.push({ name: 'ContentsSttaffStoreDeliveries' })        
      },
      openStoreDiscounts() {
        this.$router.push({ name: 'ContentsSttaffStoreDiscounts' })        
      },
      configLanguage() {
        this.load()
      },
      setTabActive(value) {
        this.tabActive = value

        if(value == 'en_menu') {          
          this.tableNoMenu.rowSelected = null
        } 
        
        if(value == 'sin_menu') {          
          this.table.rowSelected = null
        }
        
      },    
      
      // SOURCE CODE
      openSourceCode(item) {
        this.crud.sourceCode.id = item.id
        this.crud.sourceCode.html = item.source_code
        this.crud.sourceCode.css = item.css
        this.crud.sourceCode.js = item.js

        this.variablesUsedSourceCode()
        this.variablesAvailablesSourceCode()
        this.variablesAvailablesSettingsSourceCode()

        this.modal.sourceCode.title = "Edición del código fuente. Bloque <<" + item.block.name + ">>"
        this.modal.sourceCode.active = true
        
        window.addEventListener("keydown", this.forceSaveSourceCode);
      },
      saveSourceCode() {        
        this.$bvModal.msgBoxConfirm('Guardar código fuente?', {
          title: 'Guardar código funete',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'success',
          okTitle: 'GUARDAR',
          cancelTitle: 'Cancelar',
          footerClass: 'p-2',
          headerBgVariant: 'success',
          headerTextVariant: 'white',
          hideHeaderClose: false,
          centered: true
        })
        .then(value => {
          if (value) {       
            let loader = this.$loading.show();
          
            var result = serviceAPI.editarPaginaBloqueSource(this.crud.sourceCode);
        
            result.then((response) => {
              //this.modal.sourceCode.active = false      
              loader.hide()
              this.load()
              this.$awn.success("Código fuente guardado con éxito");
              
              window.removeEventListener("keydown", this.forceSaveSourceCode);             
            })
            .catch(error => {
              loader.hide()
              this.$awn.alert(Error.showError(error));
            })          
          } 
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error));
        })          
      },
      cancelSourceCode() {
        this.$bvModal.msgBoxConfirm('¿Salir sin guardar de la edición del código fuente?', {
          title: 'Salir de la edición',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'danger',
          okTitle: 'SI',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          headerBgVariant: 'danger',
          headerTextVariant: 'white',
          hideHeaderClose: false,
          centered: true
        })
        .then(value => {
          if (value) {        
            this.modal.sourceCode.active = false      
            window.removeEventListener("keydown", this.forceSaveSourceCode);             
          }
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error));
        })   
      },
      forceSaveSourceCode(e) {                
        // ctrl + s = 83
        if (e.ctrlKey && e.keyCode === 83) {           
          e.preventDefault();
          
          if(this.modal.sourceCode.active) {          
            this.saveSourceCode()              
          } 
        } 
      },
      variablesUsedSourceCode() {      
        var result = serviceAPI.mostrarVariableUsedBloquesSource(this.crud.sourceCode)
        
        this.source.variablesUsed = null
        result.then((response) => {          
          var data = response.data
          this.source.variablesUsed = data

          this.configViewerJson()
        })
        .catch(error => {
          this.source.variablesUsed = null
        });   
      },
      variablesAvailablesSourceCode() {      
        var result = serviceAPI.mostrarVariableAvailablesBloquesSource(this.crud.sourceCode)
        
        this.source.variablesAvailables = null
        result.then((response) => {          
          var data = response.data
          this.source.variablesAvailables = data

          this.configViewerJson()
        })
        .catch(error => {
          this.source.variablesAvailables = null
        });   
      }, 
      variablesAvailablesSettingsSourceCode() {      
        var result = serviceAPI.mostrarVariableAvailablesSettingsBloquesSource(this.crud.sourceCode)
        
        this.source.variablesAvailablesSettings = null
        result.then((response) => {          
          var data = response.data
          this.source.variablesAvailablesSettings = data

          this.configViewerJson()
        })
        .catch(error => {
          this.source.variablesAvailablesSettings = null
        });   
      },                
      configViewerJson() {    
        setTimeout(()=>{
          var arrElement = document.getElementsByClassName("json-tree-indent");        
          if(arrElement) {
            for (var i = 0; i < arrElement.length; i++){
              if(arrElement[i].innerHTML != '&nbsp;') {
                arrElement[i].innerHTML = '&nbsp;';            
              }                        
            }                              
          }
        },500)                    
      },   
      getHelp(value) {
        var arrHelp = []
        arrHelp[0] = `Para agregar una variable al código, se tiene que escribir el nombre de la variable entre dos llaves. EJ: {{variable}}`
        arrHelp[1] = `La variable, antes de las llave de apertura y luego del cierre de llaves, tiene que tener un espacio.`
        arrHelp[2] = `Para darle un formato al dato desde la variable, seguido al nombre de la variable, agregar el caracter pleca (|) y luego el tipo de dato. EJ: {{variable|date}}`
        arrHelp[3] = `Los formatos de datos disponibles actualmente son: date, currency, int, decimal, forms, settings, instagram`
        arrHelp[4] = `Para utilizar valores de la congfiguración general del sitio web, solo debe agregar entre llaves, el nombre de la variable y el formato "settings". EJ: ({{variable|settings}}),.`
        arrHelp[5] = `Al aplicar estilos, diferenciar a las clases con un nombre único, ya que estan disponibles en todo el sitio web.`
        arrHelp[6] = `Para agregar un bloque con repetición, arriba del bloque a repetir agregar de la siguiente manera: {{details|arrayStart}} y al final del bloque {{details|arrayEnd}}`        
        
        return arrHelp[value]
      },      

      // FILTROS SAVE
      saveFilterStorage() {
        Storage.setValue('filter_content_pages', JSON.stringify({
          language: this.language.selected,
          tab: this.tabActive,
          indexTableMenu: this.indexTableMenuActive,
          indexTableNoMenu: this.indexTableNoMenuActive,
        }))
      },
      restoreFilterStorage() {
        if(Storage.getValue('filter_content_pages')) {
          var filters = JSON.parse(Storage.getValue('filter_content_pages'))

          this.language.selected = filters.language
          this.tabActive  = filters.tab

          if(this.tabActive == 'en_menu') {
            this.openDetail(filters.indexTableMenu)
          } 

          if(this.tabActive == 'sin_menu') {            
            this.openDetailNoMenu(filters.indexTableNoMenu)          
          }                    
        }         
      },      
    }    
  }
</script>

<style>
  .contents-page-titulo-page {
    font-size: 17px;
  }
  .contents-page-table-observations {
    color: darkblue;
  }
  .contents-page-tablesub-observations-not-configurate {
    color: darkred;
  }
  .contents-page-tablesub-observations-configurate {
    color: darkgreen;
  }  
  .content-pages-block-source-code {
    height: 400px;
  }
  .content-pages-block-source-code pre{
    height: 400px;
  }  
  .source-code-pre-json {
    overflow: auto;    
    height: 680px;
    width: 100%;
    min-width: unset;
  }  
</style>
<style scoped>
  .crud-page-card-language .card-body {
    padding-top: 5px;
    padding-bottom: 5px;
  }
</style>